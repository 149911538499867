














































import BaseModel, { Form } from '../../../application/model/BaseModel'
import { Component, Prop, Ref } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Hospital from '@/application/model/Hospital'
import {
  GETTER_SELECTED_HOSPITAL,
  NAMESPACE_VITALS_THRESHOLDS
} from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import {
  buildDeepQualifiedStoreMethodPath,
  buildQualifiedStoreMethodPath,
  NAMESPACE_SELECTED_HOSPITAL
} from '@/application/store/RootStore'
import { VitalsThreshold } from '@/application/model/Thresholds'
import { ACTION_PUT_ALL_VITALS_THRESHOLDS } from '@/application/store/modules/selected/hospital/SelectedHospitalVitalsThresholdsModule'

@Component
export default class EditVitalsThresholdView extends BaseModel {
  @Prop({ required: true })
  vitalsThreshold!: VitalsThreshold

  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_THRESHOLDS), ACTION_PUT_ALL_VITALS_THRESHOLDS))
  putVitalsThresholds!: () => Promise<Array<VitalsThreshold>>

  @Getter(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, GETTER_SELECTED_HOSPITAL))
  selectedHospital!: Hospital

  @Ref('FORM_REF')
  form!: Form

  isFormValid = true

  isDialogOpen = false

  constructor () {
    super('vital-sign.threshold')
  }

  save (): void {
    if (this.form.validate()) {
      this.putVitalsThresholds()
        .then(() => {
          this.isDialogOpen = false
        })
    }
  }

  cancel (): void {
    this.form.reset()
    this.isDialogOpen = false
  }
}
