



































import { Component, Ref } from 'vue-property-decorator'
import BaseModel, { Form } from '../../application/model/BaseModel'
import { Action } from 'vuex-class'
import {
  MUTATION_SELECTED_HOSPITAL,
  STATE_SELECTED_HOSPITAL
} from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import {
  buildQualifiedStoreMethodPath,
  buildQualifiedStoreStatePath,
  NAMESPACE_HOSPITALS,
  NAMESPACE_SELECTED_HOSPITAL
} from '@/application/store/RootStore'
import { ACTION_PUT_HOSPITAL } from '@/application/store/modules/HospitalsModule'
import Hospital from '@/application/model/Hospital'
import HospitalVitalsThresholds from '@/components/hospital/vitalthresholds/HospitalVitalsThresholdsView.vue'
import { State2Way } from 'vuex-class-state2way'

@Component({
  components: { HospitalVitalsThresholds }
})
export default class HospitalView extends BaseModel {
  @Action(buildQualifiedStoreMethodPath(NAMESPACE_HOSPITALS, ACTION_PUT_HOSPITAL))
  putHospital!: (hospital: Hospital) => Promise<Hospital>

  @State2Way(buildQualifiedStoreMethodPath(NAMESPACE_SELECTED_HOSPITAL, MUTATION_SELECTED_HOSPITAL),
    buildQualifiedStoreStatePath(NAMESPACE_SELECTED_HOSPITAL, STATE_SELECTED_HOSPITAL))
  selectedHospital!: Hospital

  updatedHospital: Hospital = new Hospital()

  @Ref('FORM_REF')
  form!: Form

  isFormValid = true

  isHospitalEditable = false

  constructor () {
    super('hospital')
  }

  async mounted (): Promise<void> {
    this.updatedHospital = JSON.parse(JSON.stringify(this.selectedHospital))
  }

  updateHospital (): void {
    if (this.form.validate()) {
      this.putHospital(this.updatedHospital)
        .then(result => {
          this.selectedHospital = result
          this.isHospitalEditable = false
        })
    }
  }
}
