






















import BaseModel from '../../../application/model/BaseModel'
import { Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { VitalsThreshold } from '@/application/model/Thresholds'
import EditVitalsThresholdView from '@/components/hospital/vitalthresholds/EditVitalsThresholdView.vue'
import { NAMESPACE_VITALS_THRESHOLDS } from '@/application/store/modules/selected/hospital/SelectedHospitalModule'
import { buildDeepQualifiedStoreMethodPath, NAMESPACE_SELECTED_HOSPITAL } from '@/application/store/RootStore'
import { DataTableHeader } from 'vuetify'
import {
  ACTION_FETCH_ALL_VITALS_THRESHOLDS,
  GETTER_ALL_VITALS_THRESHOLDS
} from '@/application/store/modules/selected/patient/SelectedPatientVitalsThresholdsModule'

@Component({
  components: { EditVitalsThresholdView }
})
export default class HospitalVitalsThresholdsView extends BaseModel {
  @Action(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_THRESHOLDS), ACTION_FETCH_ALL_VITALS_THRESHOLDS))
  fetchHospitalVitalsThresholds!: () => Promise<Array<VitalsThreshold>>

  @Getter(buildDeepQualifiedStoreMethodPath(Array.of(NAMESPACE_SELECTED_HOSPITAL, NAMESPACE_VITALS_THRESHOLDS), GETTER_ALL_VITALS_THRESHOLDS))
  hospitalVitalsThresholds!: Array<VitalsThreshold>

  constructor () {
    super('vital-sign')
  }

  get tableHeaders (): Array<DataTableHeader> {
    return [
      {
        text: this.getLangText('type'),
        value: 'vitalSignType'
      },
      {
        text: this.getLangText('threshold.minValue'),
        value: 'minValue'
      },
      {
        text: this.getLangText('threshold.maxValue'),
        value: 'maxValue'
      },
      {
        text: '',
        value: 'edit'
      }
    ]
  }

  async mounted (): Promise<void> {
    await this.fetchHospitalVitalsThresholds()
  }
}
